<template>
	<form class="form form--organization form-organization" @submit.prevent="submitForm(fields)">
		<div class="form__loader" v-if="loading || isLoading">
			<loading-spinner />
		</div>

		<div class="form-section">
			<div class="form-row form-row--col-2">
				<text-field class="form__field" label="Name" v-model="fields.name" :required="true" />
				<text-field class="form__field" type="tel" label="Phone" v-model="fields.phone" :required="true" />
				<text-field class="form__field" type="url" label="Website" v-model="fields.url" :required="true" />
				<select-field class="form__field" label="Status" v-model="fields.status" :options="organizationStatus" />
			</div>

			<h2 class="form-section__title">Address Information</h2>
			<div class="form-row form-row--col-2">
				<text-field class="form__field" label="Street" v-model="fields.address.line1" />
				<text-field class="form__field" label="Street (cont)" v-model="fields.address.line2" />
				<text-field class="form__field" label="City" v-model="fields.address.city" />
				<select-field class="form__field" label="State" v-model="fields.address.state" :options="states" />
				<text-field class="form__field" label="Postal Code" v-model="fields.address.postal" />
			</div>

			<h2 class="form-section__title">Settings</h2>
			<div class="form-row form-row--col-2">
				<text-field class="form__field" label="Cert Number" v-model="fields.settings.certNumber" />
				<text-field class="form__field" label="SNL Number" v-model="fields.settings.snlNumber" />
			</div>

			<div class="form-row form-row--col-3">
				<text-field class="form__field" label="Internal Markup" v-model="fields.settings.institutionalMarkup" type="tel"><span slot="post">%</span></text-field>
				<text-field class="form__field" label="External Markup" v-model="fields.settings.externalMarkup" type="tel"><span slot="post">%</span></text-field>
				<text-field class="form__field" label="Institutional Markup" :value="institutionalMarkup" type="tel" :disabled="true"><span slot="post">%</span></text-field>
				<text-field class="form__field" label="Salesforce ID" v-model="fields.settings.salesforceId" :readonly="true">
					<a slot="post" @click="getSalesforceId()">sync</a>
				</text-field>
			</div>

			<div class="alert alert--fail" v-if="error">
				{{ error }}
			</div>

			<div class="form__actions form__actions--horizontal">
				<a-button class="button--primary form__action" type="submit" :disabled="loading || isLoading">
					{{ fields.orgId ? `Update` : `Create` }} Organization
				</a-button>
			</div>

		</div>
	</form>
</template>

<script>
import AButton from '@/components/AButton'
import Checkbox from '@/components/Checkbox'
import LoadingSpinner from '@/components/LoadingSpinner'
import SelectField from '@/components/SelectField'
import TextField from '@/components/TextField'
import { OrganizationStatus } from '@/lib/enums'
import stateList from '@/assets/stateList.json'
import { get, pick } from 'lodash'

export default {
	name: 'Formorganization',
	components: {
		AButton,
		Checkbox,
		LoadingSpinner,
		SelectField,
		TextField,
	},
	props: {
		loading: Boolean,
		organization: {
			type: Object,
			default: () => ({}),
		},
	},
	data: () => ({
		defaults: {
			address: {},
			permissions: {},
			settings: {},
		},
		error: null,
		fields: {},
		toUpload: null,
		isLoading: false,
		isLogoLoading: false,
		states: stateList,
	}),
	computed: {
		applications() {
			return this.$store.getters['application/applications']
		},
		organizationStatus() {
			let status = []
			for (let key in OrganizationStatus) {
				status.push({
					value: key,
					label: OrganizationStatus[key],
				})
			}

			return status
		},
		institutionalMarkup() {
			return Number((Number(this.fields.settings.institutionalMarkup || 0) + Number(this.fields.settings.externalMarkup || 0)).toFixed(2))
		},
	},
	methods: {
		submitForm(fields) {
			this.error = null
			this.isLoading = true
			if (fields.orgId) {
				this.$store.dispatch('organization/update', { orgId: fields.orgId, data: fields })
					.then(() => {
						this.$notice(`Organization updated!`)

						if (this.toUpload) {
							this.uploadLogo(this.toUpload)
						}
					})
					.catch((error) => {
						this.error = error.message || error
					})
			} else {
				this.$store.dispatch('organization/create', fields)
					.then(() => {
						this.$notice(`Organization created!`)

						if (this.toUpload) {
							this.uploadLogo(this.toUpload)
						}
					})
					.catch((error) => {
						this.error = error.message || error
					})
			}
			this.isLoading = false
		},
		getSalesforceId() {
			if (this.$route.params.orgId) {
				this.loading = true
				this.$store.dispatch('organization/salesforce', this.$route.params.orgId)
					.catch((error) => {
						this.$notice(`ERROR: ${error.message || error}`)
					})
					.finally(() => {
						this.loading = false
					})
			}
		},
		remove(fields) {
			if (!fields.orgId) return

			this.$confirm(`Are you sure you want to remove "${fields.name}"?`, () => {
				this.$store.dispatch('organization/remove', fields.orgId ).then(() => {
					this.$notice(`Organization removed!`)
					this.$router.back()
				}).catch(error => {
					this.$notice(`ERROR: ${error.message || error}`)
				})
			})
		},
	},
	watch: {
		organization: {
			immediate: true,
			handler: function (orgProp) {
				this.fields = {
					...this.defaults,
					...this.fields,
					...pick(orgProp, ['orgId', 'address', 'createdBy', 'createdByName', 'dateCreated', 'dateModified', 'modifiedBy', 'modifiedByName', 'name', 'permissions', 'phone', 'settings', 'status', 'url',]),
				}
			},
		},
	},
}
</script>

<style scoped lang="scss">
.form-organization {

	&__logo {
		@include modules.gutter('margin-bottom');
		max-width: 200px;

	}

	&__upload {
		position: relative;

		&--loading {
			opacity: 0.5;
		}

		&-loading {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate3d(-50%, -50%)
		}
	}
}
</style>
